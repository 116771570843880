$(function () {
	if ($(".g-recaptcha").length > 0) {
		loadCaptcha();
	}

    jqueryDatepicker($);
    const datepicker = $(".datepicker");
	datepicker.datepicker({
        dateFormat: 'yy-mm-dd',
        minDate: datepicker.is('[name=giftcard_date]') ? Date.now() : new Date('1900-01-01'),
        maxDate: datepicker.is('[name=giftcard_date]') ? null : new Date(Date.now() - (18 * 365 + 4) * 24 * 60 * 60 * 1000),
        defaultDate: datepicker.is('[name=giftcard_date]') ? Date.now() : new Date('1970-01-01'),
        changeYear: !datepicker.is('[name=giftcard_date]'),
        yearRange: "c-200:c+200",
    });

	var number = 0;

	$("#sortpicture1").change(function () {
		var file_data = $("#sortpicture1").prop("files")[0];
		var form_data = new FormData();
		form_data.append("file", file_data);
		form_data.append("type", "opi");
		$.ajax({
			url: "https://ftp.dev.zero11.net/storage/opificio/up.php", // point to server-side PHP script
			dataType: "text", // what to expect back from the PHP script, if anything
			cache: false,
			contentType: false,
			processData: false,
			data: form_data,
			type: "post",
			success: function (php_script_response) {
				if (JSON.parse(php_script_response).status == "OK") {
					$("#upload-div-1").addClass("hide");
					$("#image-uploaded-1").removeClass("hide");
					$("#link-image1").val(JSON.parse(php_script_response).link);
					number++;
				} else {
					showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
				}
			},
			error: function () {
				showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
			},
		});
	});

	$("#profile-consent-form").on('submit',function (event) {
		event.preventDefault();
		var $form = $(this);

		var formData = {
			userConsents: [],
		};

		$form.find('input[type=checkbox]').each(function () {
			var obj = {};
			obj.consentId = $(this).attr("name");
			obj.value = $(this).is(":checked");
			formData.userConsents.push(obj);
		});

		$.ajax({
			type: $form.attr("method"),
			url: $form.attr("action"),
			data: JSON.stringify(formData),
			dataType: "json",
			contentType: "application/json",
			beforeSend: function (data) {},
			success: function (data) {
				showMessageBox("success", msg["MSG_PROFILECONSENT_SAVED"]);
			},
			failed: function (data) {
				showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
			},
			complete: function (data) {},
		});
	});

	$("#to-second-step").on("click", function () {
		to_second_step();
	});

	$("#back-step-1").on("click", function () {
		to_step_1();
	});

	$("#back-step-2").on("click", function () {
		to_step_2();
	});

	$("#back-step-3").on("click", function () {
		to_step_3();
	});

	$("#to-third-step").on("click", function () {
		to_third_step();
	});

	$("#to-four-step").on("click", function () {
		to_four_step();
	});

	$(".private-step1").on("click", function () {
		to_step_1();
	});

	$(".private-step2").on("click", function () {
		to_second_step();
	});

	$(".private-step3").on("click", function () {
		to_third_step();
	});

	$(".private-step4").on("click", function () {
		to_four_step();
	});

	$(".private-send").on("click", function () {
		to_send_private();
	});
});

function findCheckedInput(name) {
	var result = "";
	var x = document.getElementsByName(name);
	var i;
	for (i = 0; i < x.length; i++) {
		if (x[i].checked == true) {
			result = result.concat(x[i].value) + " ";
		}
	}
	return result;
}

function sendRequestCaptcha() {
	$("#contact-no-quantity input[type=submit]").prop("disabled", false);
	sendRequest();
}

function sendRequest() {
	var $form = $("#contact-no-quantity :input, #contact-no-quantity textarea");

	if (document.getElementById("email-contact").value != "") {
		$.ajax({
			type: "POST",
			url: "/restful/delegate/form/save",
			data: $form.serialize(),
			dataType: "json",
			contentType: "application/x-www-form-urlencoded",
			beforeSend: function (data) {},
			success: function (data) {
				showMessageBox("success", msg["MSG_MESSAGE_SENT"]);
			},
			failed: function (data) {
				showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
				grecaptcha.reset();
			},
			complete: function (data) {},
		});
	} else {
		showMessageBox("danger", msg["ERR_FILLEMAILFIELD"]);
		grecaptcha.reset();
	}
}

function contactFormRecaptchaBusiness() {
	$("#contact-form-professionista").validate({
		errorPlacement: function (error, element) {
			error.appendTo("");
		},
	});

	if ($("#privacy-professionista").is(":checked") == true) {
		if ($("#contact-form-professionista").valid()) {
			$.ajax({
				type: $("#contact-form-professionista").attr("method"),
				url: $("#contact-form-professionista").attr("action"),
				data: $("#contact-form-professionista").serialize(),
				dataType: "json",
				beforeSend: function (data) {},
				success: function (data) {
					if (data.success) {
						showMessageBox("success", msg["MSG_MESSAGE_SENT"]);
					} else {
						showMessageBox("danger", data.errors.message);
					}
				},
				failed: function (data) {
					showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
					grecaptcha.reset();
				},
				complete: function (data) {},
			});
		} else {
			grecaptcha.reset();
		}
	} else {
		showMessageBox("danger", msg["ERR_PRIVACY"]);
		grecaptcha.reset();
	}
}

function to_step_1() {
	$("#private-contact-step1").removeClass("hide");
	$("#private-contact-step2").addClass("hide");
	$("#private-contact-step3").addClass("hide");
	$("#private-contact-step4").addClass("hide");
}


function to_step_2() {
	$("#private-contact-step1").addClass("hide");
	$("#private-contact-step2").removeClass("hide");
	$("#private-contact-step3").addClass("hide");
	$("#private-contact-step4").addClass("hide");
}


function to_step_3() {
	$("#private-contact-step1").addClass("hide");
	$("#private-contact-step2").addClass("hide");
	$("#private-contact-step3").removeClass("hide");
	$("#private-contact-step4").addClass("hide");
}

function to_second_step() {
	$("#private-contact").validate({
		errorPlacement: function (error, element) {
			error.appendTo("");
		},
	});

	if ($("#private-contact").valid() == true) {
		$("#private-contact-step1").addClass("hide");
		$("#private-contact-step2").removeClass("hide");
		$("#private-contact-step3").addClass("hide");
		$("#private-contact-step4").addClass("hide");
	}
}

function to_send_private(number) {
	$("#private-contact").validate({
		errorPlacement: function (error, element) {
			error.appendTo("");
		},
	});
}

function to_third_step() {
	$("#private-contact").validate({
		errorPlacement: function (error, element) {
			error.appendTo("");
		},
	});

	if ($("#private-contact").valid() == true) {
		$("#private-contact-step1").addClass("hide");
		$("#private-contact-step2").addClass("hide");
		$("#private-contact-step3").removeClass("hide");
		$("#private-contact-step4").addClass("hide");
	}
}

function to_four_step() {
	$("#private-contact").validate({
		errorPlacement: function (error, element) {
			error.appendTo("");
		},
	});

	if ($("#private-contact").valid() == true) {
		$("#private-contact-step1").addClass("hide");
		$("#private-contact-step2").addClass("hide");
		$("#private-contact-step3").addClass("hide");
		$("#private-contact-step4").removeClass("hide");

		document.getElementById("private-contact-name-lastname").innerHTML =
			$("#private-name").val();
		document.getElementById("private-contact-email").innerHTML =
			$("#private-email").val();
		document.getElementById("private-contact-phone").innerHTML =
			$("#private-phone").val();
		document.getElementById("private-contact-city").innerHTML =
			$("#private-city").val();

		document.getElementById("private-contact-description").innerHTML =
			findCheckedInput("rinnova");

		document.getElementById("private-contact-dimensions").innerHTML = 
			$("#private-space-dimensions").val();
			
		document.getElementById("private-contact-layout-input").innerHTML =
			findCheckedInput("layout");

		if (number != 0) {
			$("#number-count").html($("#number-count").html() + number);
		} else {
			$("#private-image-div").addClass("hide");
		}
	}
}

function contactRecaptchaCallbackPrivate() {
	if ($("#privacy-private").is(":checked") == true) {
		$.ajax({
			type: $("#private-contact").attr("method"),
			url: $("#private-contact").attr("action"),
			data: $("#private-contact").serialize(),
			dataType: "json",
			beforeSend: function (data) {},
			success: function (data) {
				if (data.success) {
					showMessageBox("success", msg["MSG_MESSAGE_SENT"]);
					$("#private-contact-submit").addClass("private-button-disabled");
				} else {
					showMessageBox("danger", data.errors.message);
				}
			},
			failed: function (data) {
				showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
				$("#private-contact").foundation("close");
				grecaptcha.reset();
			},
			complete: function (data) {},
		});
	} else {
		showMessageBox("danger", msg["ERR_PRIVACY"]);
		grecaptcha.reset();
	}
}

function contactFormRecaptcha() {
	if (document.getElementById("privacy").checked) {
		$("#contact-form-send input[type=submit]").prop("disabled", false);
		sendContact();
	} else {
		showMessageBox("danger", msg["ERR_PRIVACY"]);
		grecaptcha.reset();
	}
}

function sendContact() {
	var $form = $("#contact-form");

	if ($("#email").val() == "") {
		showMessageBox("danger", msg["ERR_FILLEMAILFIELD"]);
		grecaptcha.reset();
        return;
	}

    let allRequired = true;
    for (const input of $form.find('[required]').toArray()) {
        if ((input.type == 'checkbox' && !input.checked) || input.value.length == 0) {
            allRequired = false;
            break;
        }
    }

    if (!allRequired) {
		showMessageBox("danger", msg["ERR_MISSINGFIELDS"]);
		grecaptcha.reset();
        return;
    }
    
    $.ajax({
        type: $form.attr("method"),
        url: $form.attr("action"),
        data: $form.serialize(),
        dataType: "json",
        contentType: "application/x-www-form-urlencoded",
        success: function (data) {
            if (data.success) {
                showMessageBox("success", msg["MSG_MESSAGE_SENT"]);
                const $afterSubmit = $('#after-submit');
                if ($afterSubmit.length > 0) {
                    $form.addClass('hide');
                    $afterSubmit.removeClass('hide');
                }
            }
            else {
                showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
            }
            grecaptcha.reset();
        },
        failed: function () {
            showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
            grecaptcha.reset();
        }
    });
}
